.footer {
  margin-top: 15px;
  text-align: center;

  position: relative;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;

  font-size: 12px;
  color: #999;
}

.footer img {
  max-height: 30px;
  margin-right: 5px;
}
