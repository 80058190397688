:root {
  --primary: #00da91;
  --dark: #272726;
}

html,
body {
  background-color: #f6f8fa;
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #00c481;
  border-color: #00c481;
}
